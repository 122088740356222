#get-applo {
  padding: 120px 0;
  background-size: cover;
  background-image: url(../../static/applo/5.jpg);
  // background-attachment: fixed

  &.get-applo-two {
    background-image: url(../../static/applo/6.jpg) !important; }


  .applo {
    border-bottom: 5px solid $color_theme; }


  .section-title {
    color: #FFF;
    margin-top: 80px; }

  .applo-caption {
    color: #FFF;
    margin-bottom: 30px;
    @include font-size(16);
    @include line-height(28);
    margin-bottom: 130px; }

  .download {
    text-align: center;
    margin-top: 40px;

    h4 {
      color: #FFF;
      letter-spacing: 2px;
      @include font-size(18);
      text-transform: uppercase;
      margin: 0;


      span {
        color: $color_theme; } } } }

.app-btn {
  display: inline-block;
  margin-right: 30px;
  background-color: #cadcf1;
  border-radius: 5px;
  padding: 15px 40px;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  color: #222;
  font-weight: 600;

  img {
    float: left;
    margin-right: 15px; }

  i {
    @include font-size(40);
    margin-right: 15px;
    float: left; }

  p {
    margin-bottom: 0;
    @include font-size(16);
    text-transform: uppercase; }

  &:last-child {
    margin-right: 0; }

  &:hover {
    text-decoration: none;
    background: $color_theme;
    color: #FFF;

    i {
      transform: rotate(360deg); } } }


.countup {
  margin-top: 100px;
  .count-bg {
    height: 175px;
    width: 200px;
    position: relative;
    margin: 0 auto;

    svg {
      width: 100%;
      fill: rgba(255, 171, 49, 0.2);
      stroke: rgb(255, 151, 0);
      stroke-width: 2px; }

    .count {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;

      h2 {
        @include font-size(48);
        color: #FFF;
        font-weight: 700; }

      p {
        margin-bottom: 0;
        color: #FFF;
        font-weight: 500;
        text-transform: uppercase;
        @include font-size(18); } } } }


/* Get Applo Two*/
.title-two {
  span {
    color: #00ffff !important; } }

.app-btn-two {
  transition: all 0.3s ease-in-out;

  &:hover {
    background: $color_theme-2; } }

.color-two {
  span {
    color: #00ffff !important; } }

.countup {
  .svg-color-1 {
    svg {
      fill: rgba(183, 31, 241, 0.2);
      stroke: #b71ff1; } }

  .svg-color-2 {
    svg {
      fill: rgba(0, 255, 255, 0.2);
      stroke: #00ffff; } }

  .svg-color-3 {
    svg {
      fill: rgba(87, 173, 57, 0.2);
      stroke: #57ad39; } } }

