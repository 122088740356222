.dt-menu-btn {
  color: #FFF;
  background: $color_theme;
  cursor: pointer;
  padding: 8px 0 !important;
  display: block;
  font-weight: 600;
  position: relative;
  transition: all 0.3s ease-in-out;
  @include font-size(14);
  margin-bottom: 20px;
  border-radius: 5PX;
  text-transform: uppercase;
  font-weight: 700;

  &:hover {
    background: #FFF !important;
    text-decoration: none;
    color: #111; } }

.dt-btn {
  padding: 12px 60px;
  border-radius: 30px;
  display: inline-block;
  @include font-size(16);
  font-weight: 700;
  color: #FFF;
  transition: all 0.3s ease-in-out;
  background: $color_theme;

  &:hover {
    background: #000;
    border-color: transparent;
    text-decoration: none;
    color: #FFF; } }









