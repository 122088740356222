#feature {
  background-size: cover;
  .about {
    padding: 100px 0;
    border-bottom: 1px solid $color_theme;
    border-right: 1px solid $color_theme;

    p {
      @include font-size(18);
      @include line-height(25);
      color: #4a4a4a; }

    .feature-thumb {
      max-width: 500px;
      margin: 0 auto;
      text-align: center;

      img {
        width: 100%; } } }


  .feature {
    padding: 50px 0;
    border-left: 1px solid $color_theme;

    .feature-thumb {
      max-width: 500px;
      margin: 0 auto;
      text-align: center;

      img {
        width: 100%; } }

    .feature-item {
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        padding: 10px 0;
        @include font-size(18);
        font-weight: 500;
        color: #4a4a4a;

        img {
          margin-right: 10px; } } } } }


/* Feature Two */
#feature-two {
  padding: 200px 0;
  text-align: center;
  position: relative;


  // &:before
  //   position: absolute
  //   height: 100px
  //   width: 100%
  //   content: ''
  //   left: 90px
  //   top: -50px
  //   background: #c21fff
  //   border-top-right-radius: 77%

  // &:after
  //   position: absolute
  //   height: 110px
  //   width: 100%
  //   content: ''
  //   left: 43px
  //   top: -60px
  //   background: #fff
  //   border-top-right-radius: 110%

  .feature_content {
    text-align: left; }

  .feature-item {
    margin-bottom: 50px;
    i {
      float: left;
      @include font-size(40);
      color: $color_theme; }

    .content {
      margin-left: 60px;

      h4 {
        color: #00063e; } } }

  .feature_content {
    h2 {
      color: #000000;
      margin-bottom: 50px;
      @include font-size(40);
      font-weight: 700; }

    p {
      color: #333333;
      @include line-height(28); } }

  .feature-thumb {
    position: absolute;
    left: -250px;
    top: -130px;
    width: 900px;
    z-index: 50;

    img {
      width: 100%; } } }



