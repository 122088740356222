#blog_single {
  padding: 90px 0;

  .widget {
    margin-left: 70px; } }

.blog_single_post {
  .post_thumb {
    margin-bottom: 20px;

    img {
      width: 100%; } }

  .post-meta {
    li {
      a {
        color: #999999; }

      &:first-child {
        &:after {
          background: #999; } } } }

  .blog_title {
    color: #474747;
    @include font-size(28);
    margin-bottom: 20px; }

  .blog_content {
    p {
      color: #424242;
      @include font-size(16);
      @include line-height(24);
      margin-bottom: 20px; }

    blockquote {
      text-align: center;
      color: $color_theme;
      font-weight: 500;
      @include font-size(24);
      @include line-height(20);
      font-style: italic;
      padding: 0;
      border-left: none;
      margin: 30px 0; }


    .social_link {
      text-align: center;
      margin-top: 20px;
      margin-bottom: 40px; } } }


.admin {
  border: 2px solid $color_theme;
  border-radius: 50px;
  padding: 10px 20px;
  margin-bottom: 50px;

  .admin_thumb {
    float: left;

    img {
      border-radius: 50%; } }

  .admin-say {
    margin-left: 100px; }

  .name {
    color: #2f2d2d;
    @include font-size(16);
    font-weight: 500;
    margin-top: 5px; }

  p {
    margin-bottom: 0;
    color: #414141;
    @include line-height(22); } }


.comments-area {

  ul {
    list-style: none; }

  .comment-list {
    margin: 0;
    padding: 0;

    li {
      .comment-body {
        border-bottom: 1px solid #ebebeb;
        margin-bottom: 20px;
        position: relative;
        padding: 20px 0;

        .user_thumb {
          float: left;
          height: 80px;
          width: 80px;
          border-radius: 50%;
          border: 3px solid $color_theme;

          img {
            width: 100%;
            border-radius: 50%; } }

        .comment-content {
          margin-left: 100px;

          h4 {
            margin-top: 0;
            @include font-size(16);
            text-transform: uppercase;
            font-weight: 500;
            color: #333; }

          p {
            color: #666666; }

          a {
            color: $color_theme;
            @include font-size(12);
            text-decoration: none; } }

        .comment-meta {
          position: absolute;
          left: 40%; } } } } }

.comment-respond {
  .comment-reply-title {
    @include font-size(24);
    font-weight: 500;
    color: #333;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    margin-top: 40px;
    margin-bottom: 60px; } }




#commentform {
  input[type="text"] {
    width: 47%;
    margin-right: 10px;
    padding: 10px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #333;
    outline: none;
    margin-bottom: 30px;
    tyransition: all 0.3s ease-in-out;

    &:hover {
      border-color: $color_theme; } } }


.comment-form-comment {
  #comment {
    width: 100%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #333;
    outline: none;
    height: 100px;
    padding: 10px;
    tyransition: all 0.3s ease-in-out;
    margin-bottom: 30px;

    &:hover {
      border-color: $color_theme; } } }

