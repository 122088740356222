/* Widget Resent Post */
.recent_post {
  .post_items {
    margin-bottom: 30px;

    .recent_post_thumb {
      float: left;
      border-radius: 5px;

      img {
        border-radius: 5px; } }

    .post_content {
      margin-left: 100px;

      h6 {
        @include font-size(14);
        font-weight: 500;
        margin-top: 0;
        @include line-height(24);
        color: #2b2b2b; }

      .post_date {
        color: #ababab;
        font-weight: 500; } } } }
