/* Dt Categories */
.widget {
  .widget-title {
    color: #333333;
    @include font-size(16);
    letter-spacing: 0.3px;
    font-weight: 500;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 40px; }

  ul {
    margin-bottom: 30px;
    padding: 0;
    list-style: none;

    li {
      padding: 8px 0;

      a {
        color: #333;
        text-decoration: none;
        transition: all 0.3s ease-in-out;
        display: block;

        i {
          padding-right: 10px; }

        &:hover {
          color: $color_theme; } } } } }
