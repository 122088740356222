#applo-feature {
  text-align: center;
  padding: 100px 0;
  // background-position: center 66%
  background-size: cover;


  .caption {
    color: #000;
    @include font-size(18);
    margin-bottom: 100px; } }
.feature-wrapper {
  background-repeat: no-repeat;
  background-position: center center; }
.applo-feature {
  margin-top: 100px; }

.feature-left {
  text-align: right;

  .icon-box {
    float: right; }


  .feature-content {
    margin-right: 100px; } }

.feature-right {

  text-align: left;

  .icon-box {
    float: left; }


  .feature-content {
    margin-left: 100px; } }


.applo-feature-item {
  margin-bottom: 130px;
  cursor: pointer;
  .icon-box {
    height: 86px;
    width: 86px;
    background-color: transparent;
    padding: 4px;
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
    .feature-icon {
      height: 78px;
      width: 78px;
      background: #ffc269;
      padding: 5px;
      border-radius: 50%;
      text-align: center;
      transition: all 0.3s ease-in-out;


      i {
        border: 3px solid #FFF;
        border-radius: 50%;
        height: 68px;
        width: 68px;
        display: block;
        @include line-height(63);
        background-color: $color_theme; }

      [class^="flaticon-"] {
        &:before {
          @include font-size(30);
          color: #FFF;
          margin-left: 0; } } } }

  .feature-content {
    h4 {
      @include font-size(18);
      color: #000;
      font-weight: 500;
      transition: all 0.3s ease-in-out; }

    p {
      color: #000; } }

  &:hover {

    .icon-box {
      background: #FFF;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3); }

    .feature-content {
      h4 {
        color: $color_theme; } } } }

.position-one {
  margin-right: 68px; }

.position-right-one {
  margin-left: 68px;

  .applo-thumb {
    max-width: 350px;
    margin: 0 auto;
    img {
      margin-top: 15px; } } }

/* Applo Feature Two */

.applo-feature-item {
  .icon-box {
    .feature-icon {
      &.color-1 {
        background: #696d8d; }

      &.color-2 {
        background: #696d8d;
        i {
          background: #5fc23c; } }

      &.color-3 {
        background: #696d8d;
        i {
          background: #c21fff; } } } } }

.applo-feature-two {

  .applo-feature-item {
    &:hover {
      .feature-content {
        h4 {
          color: $color_theme-2; } } } } }

