/* Back To Top */
.return-to-top {
  position: fixed;
  bottom: 50px;
  right: 50px;
  background: $color_theme;
  width: 40px;
  height: 40px;
  text-decoration: none;
  display: none;
  transition: all 0.3s ease;
  z-index: 1000;


  i {
    color: #fff;
    margin: 0;
    position: relative;
    left: 11px;
    top: 9px;
    font-size: 19px;
    transition: all 0.3s ease; }

  &:hover {
    background: rgba(0, 0, 0, 0.9);

    i {
      color: #fff;
      top: 5px; } } }

.back-top {
 display: block; }

/* Header */

#dt-header {
  position: fixed;
  width: 100%;
  top: 80px;
  z-index: 200;
  transition: all 0.5s ease;
  z-index: 9999;

  &.headroom--pinned:not(.headroom--top) {
    top: 0;
    background-color: #fff;
    padding: 5px 0;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);

    // .home-version-2 &
    //   background-color: #c21fff
    .menu-toggle {
      span {
        background: #000; } } } }


.navbar {
  margin-bottom: 0;
  background: none;
  min-height: auto !important; }


.navbar-header {
  display: inline-block;

  .navbar-brand {
    height: auto;
    padding: 0;
    display: block;
    transition: all 0.5s ease-in-out;

    &.logo-one {
      display: block; }


    &.logo-two {
      display: none; }



    .headroom--pinned:not(.headroom--top) & {
      height: 45px;

      > img {
        height: 100%;
        width: auto; }

      &.logo-one {
        display: none; }

      &.logo-two {
        display: block; } } } }

/* Toogle Menu */
.menu-toggle {
  position: relative;
  margin-top: 18px;

  .headroom--pinned:not(.headroom--top) & {
    margin-top: 12px; }

  span {
    width: 30px;
    height: 2px;
    background: #FFF;
    margin-bottom: 8px;
    display: block;

    &:last-child {
      margin-bottom: 0; } }

  &:hover {
    cursor: pointer; } }





/* Headroom Sticky menu */
.headroom--pinned {
  display: block; }


.headroom--unpinned {
    display: none; }


.headroom {
  will-change: transform;
  transition: transform 200ms linear; }

.headroom--pinned {
  transform: translateY(0%); }

.headroom--unpinned {
  transform: translateY(-100%); }


