#newsletter {
  text-align: center;
  padding: 100px 0;
  background-size: cover;


  .section-title {
    color: #FFF; }

  .caption {
    color: #FFF;
    margin-bottom: 50px;
    @include font-size(16); } }

.search {
  margin-bottom: 50px;
  .input-group {
    .form-control {
      width: 500px;
      height: 70px;
      background-color: rgba(255, 151, 0, 0.102);
      border-radius: 38px;
      border-color: $color_theme;
      @include font-size(18);
      color: #FFF;
      outline: none;
      box-shadow: none;
      padding-right: 80px;
      padding-left: 30px; } }

  .dt-btn {
    height: 70px;
    background: $color_theme;
    @include font-size(20);
    padding: 22px 74px;
    border: transparent;
    margin-left: -70px;
    color: #FFF;
    border-radius: 38px;
    font-weight: 500; } }

.big-social-link {
  margin: 0;
  padding: 0;

  li {
    margin-right: 20px;
    display: inline-block;
    border: 2px solid $color_theme;
    height: 95px;
    width: 95px;
    border-radius: 20px;
    transition: all 0.5s ease-in-out;

    &:last-child {
      margin-right: 0; }

    a {
      color: #FFF;
      @include font-size(50);
      line-height: 100px;
      text-decoration: none;
      display: block; }



    &:hover {
      background: $color_theme; } } }

/* Newsletter Two */

.newsletter-two {
  .search {
    .input-group {
      .form-control {
        background-color: rgba(0, 255, 255, 0.102);
        border-color: #00ffff;
        color: #FFF; } }
    .dt-btn {
      background: #00ffff;
      color: #111; } }

  .social-wrapper {
    width: 470px;
    margin: 0 auto;
    h3 {
      float: left;
      margin: 0;
      @include font-size(24);
      color: #FFF;
      text-transform: uppercase;
      position: relative;
      margin-top: 6px;

      &:after {
        position: absolute;
        content: '';
        background: #5fc23c;
        height: 50px;
        width: 3px;
        right: -20px;
        top: -14px; } } }

  .dt-social-link {
    li {
      height: 36px;
      width: 36px;
      border: 3px solid #FFF;

      a {
        i {
          @include font-size(16);
          line-height: 31px;
          color: #FFF; } }

      &.color-1 {

        &:hover {
          background: #0d54e4 !important;
          a {
            color: #FFF; } } }

      &.color-2 {

        &:hover {
          background: #2693e6 !important;
          a {
            color: #FFF; } } }

      &.color-3 {

        &:hover {
          background: #12cae0 !important;
          a {
            color: #FFF; } } }

      &.color-4 {

        &:hover {
          background: #364fe8 !important;
          a {
            color: #FFF; } } }


      &:hover {
        border-color: transparent; } } } }


