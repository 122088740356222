/*--------------------------------------------------------------
  ## Dt Categories
  --------------------------------------------------------------*/
@import "widget-dt-categories";


/*--------------------------------------------------------------
  ## Dt Resent Post
  --------------------------------------------------------------*/
@import "widget-dt-resent-post";


/*--------------------------------------------------------------
  ## Widget Dt Instagram Gallary
  --------------------------------------------------------------*/
@import "widget_dt_instagram_gallary";
