.dt-slide-menu {
  width: 350px !important;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 20%;
  background-color: rgba(0, 0, 0,0.902);
  padding: 40px 60px;
  z-index: 9999;


  .dt-header-logo {
    margin: 0 auto;
    margin-bottom: 40px; } }

.dt-primary-menu {
  margin-bottom: 25px;
  padding: 0;
  list-style: none;

  li {
    padding: 4px 0;

    a {
      display: block;
      color: #FFF;
      text-decoration: none;
      font-weight: 700;
      @include font-size(18);
      transition: all 0.3s ease-in-out;
      padding: 0;

      &:hover {
        color: $color_theme; } }

    &.menu-item-has-children {

      .child-menu {
        display: none;
        list-style: none;
        margin-bottom: 0;
        padding-left: 15px;

        li {
          a {
            @include font-size(14);
            font-weight: 500; } } } }


    &.children-menu-visible {

      .child-menu {
        display: block; } } } }




.dt-secondary-menu {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    padding: 4px 0;

    a {
      display: block;
      color: #FFF;
      @include font-size(16);
      text-decoration: none;
      transition: all 0.3s ease-in-out;
      padding: 0;


      &:hover {
        color: $color_theme; } } } }

.get-in-touch {
  text-align: center;

  h3 {
    color: #FFF;
    @include font-size(18);
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 50px;
    background: transparent; }

  .flaticon-fork {
    transform: rotate(90deg);
    display: inline-block; }


  [class^="flaticon-"] {
    &:before {
      color: #FFF;
      @include font-size(30);

      margin-left: 0; } }

  .input {
    width: 100%;
    margin-bottom: 10px;
    padding: 6px 10px;
    border-radius: 5px;
    background: #30281c;
    border: 1px solid #8c8881;
    outline: none;
    transition: all 0.3s ease-in-out;
    color: #FFF;

    &:hover {
      border-color: $color_theme; } }

  #massage {
    height: 85px; } }


.cbp-spmenu-right {
  right: -350px; }

.cbp-spmenu-vertical {
  a {
    border-bottom: none;
    &:hover {
      background: none; } } }

.cbp-spmenu-push-toleft {
  &:before {
    background: rgba(0,0,0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    z-index: 10000; } }

.cbp-spmenu-push-toleft {
  left: -350px; }

.dt-social-link {
  margin: 0;
  padding: 0;
  list-style: none;


  li {
    display: inline-block;
    margin-right: 8px;
    text-decoration: none;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    text-align: center;
    border: 2px solid $color_theme;
    transition: all 0.3s ease-in-out;

    &:last-child {
        padding: 0; }
    a {
      color: $color_theme;
      transition: all 0.3s ease-in-out;
      display: block;
      transition: all 0.3s ease-in-out;
      padding: 0;

      i {
        @include font-size(12);
        @include line-height(28); } }


    &:hover {
      background: $color_theme !important;

      a {
        color: #FFF; } } } }



.menu-toggle {
  position: relative;
  width: 30px;
  height: 23px; }



.menu-toggle:hover {
  cursor: pointer; }



