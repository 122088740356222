#dt-footer {
  text-align: center;
  padding: 29px 0;
  background: $color_theme;

  p {
    margin-bottom: 0;
    color: #FFF;
    @include font-size(16);

    span {
      font-weight: 500; }

    a {
      text-decoration: none;
      color: #FFF; } } }

.home-version-2 {
  #dt-footer {
    background: #00063e; } }
