#pricing {
  padding: 180px 0;
  text-align: center;
  overflow: hidden;
  background-size: cover;
  background-position: center center;

  .section-title {
    margin-bottom: 20px;
    span {
      color: #FFF; } }


  p {
    color: #FFF;
    margin-bottom: 50px;
    font-size: 16px; } }


.price-table {
  text-align: center;
  padding: 55px 0;
  border-radius: 35px;
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  transition: all 0.3s ease;
  max-width: 400px;
  margin: 0 auto;


  h3 {
    @include font-size(36);
    color: #302e2e;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 17px;
    transition: all 0.3s ease-in-out; }

  span {
    @include font-size(18);
    display: inline-block;
    padding: 2px 27px;
    border: 1px solid #252525;
    border-radius: 30px;
    color: #302e2e;
    margin-bottom: 30px;
    font-weight: 500;
    transition: all 0.3s ease-in-out; }

  h4 {
    color: #302e2e;
    @include font-size(20);
    margin-top: 0; }

  .dt-btn {
    background-color: transparent;
    border: 2px solid #464646;
    color: #464646;

    &:hover {
      background: $color_theme;
      border-color: transparent;
      color: #FFF; } }



  &:hover {
    box-shadow: 0px 15px 50px 0px rgba(0, 0, 0, 0.3);

    h3 {
      color: $color_theme; }

    span {
      background: $color_theme;
      border-color: transparent;
      color: #FFF; } } }




.cooking {
  margin-bottom: 10px;
  margin-top: 24px;
  padding: 0;
  list-style: none;

  li {
    padding: 10px 0;
    @include font-size(16);
    color: #000;

    &.none {
      color: #ff2a00; } } }

/* Pricing Table Two */
.pricing-table-two {
  background-size: cover;
  background-position: center center;
  .price-table {
    background: #FFF;



    .btn-green {
      background-color: #5fc23c !important; }

    .btn-purple {
      background-color: #c21fff !important; }

    .btn-orange {
      background-color: $color_theme !important; }


    .dt-btn {
      border-color: transparent;
      color: #FFF;

      &:hover {
        background-color: transparent !important;
        border-color: #111;
        color: #111; } } }

  .color-green {
    color:  #5fc23c !important; }

  .color-purple {
    color:  #c21fff !important; }

  .color-orange {
    color:  $color_theme !important; } }
