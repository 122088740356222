#dt-banner {
  background-size: cover;
  overflow: hidden;
  position: relative;
  height: 100vh;
  background: $color_theme;
  background-size: cover;

  &.banner-two {
    background: #00063e; } }

#scene {
  img {
    width: 100%;
    overflow: hidden; } }


.banner-container {
  .swiper-slide {
    height: 100vh; }

  .slider-content {
    top: 50%;
    position: absolute;
    width: 100%;
    transform: translateY(-50%);

    .slider-title {
      position: relative;
      animation: bounceInDown 0.8s both 1s;


      h2 {
        position: relative;
        @include font-size(54);
        text-transform: uppercase;
        color: #FFF;
        display: inline-block;



        &:after {
          position: absolute;
          background: #cf7d06;
          height: 60px;
          width: 1px;
          right: -20px;
          top: 0;
          content: ''; } }

      span {
        color: #FFF;
        @include font-size(24);
        display: block;
        position: absolute;
        left: 26%;
        top: 30px; } }

    p {
      color: #FFF;
      @include font-size(18);
      @include line-height(30);
      font-family: 'Cabin', sans-serif;
      margin-bottom: 30px;
      animation: zoomIn 1s both 1.1s; }

    .dt-btn {
      background: #FFF;
      padding: 17px 60px;
      color: #111;
      margin-right: 20px;
      text-decoration: none;
      animation: fadeOutRight .8s both;
      animation: fadeInUp 1s both 1.3s;

      &:hover {
        background: #000;
        color: #FFF; } }

    .lern-btn {
      background: #000;
      color: #FFF;
      margin-right: 0;

      &:hover {
        background: #FFF;
        color: #000; } } }

  .dt-half-width-img {
    .slider-images {
      position: absolute;
      top: 0;
      left: 0;

      img {
        position: absolute; }

      .mobile-1 {
        z-index: 100;
        animation: bounceInRight 1s both 1s; }
      .mobile-2 {
        margin-left: 100px;
        z-index: 50;
        animation: bounceInRight 1s both 1.5s; }

      .mobile-3 {
        margin-left: 200px;
        z-index: 30;
        animation: bounceInRight 1s both 1.7s; } } } }


.dt-half-width {
  position: absolute;
  left: 15%;
  top: 50%;
  transform: translateY(-50%);
  width: 40%; }

.dt-half-width {
  &:last-child {
    right: 10%;
    left: auto;
    height: 515px; } }


.heading-line-left {
  margin: 0 0 20px;
  margin-left: -7%;
  animation: fadeOutLeft 1s both; }




.parallax-bg {
  img {
    position: absolute;
    top: 0;
    left: 0;
    height: auto;
    width: 100%; } }

/* Banner Two */
.banner-two {
  h2 {
    &:after {
      display: none; } }

  .dt-btn {
    text-transform: uppercase;

    &.color-2 {
      background: #c21fff;
      color: #FFF; } } }
