#applo-chef {
  text-align: center;
  padding: 100px 0;
  background-size: cover;
  background-position: center center;

  .caption {
    margin-bottom: 100px;
    color: #000;
    @include font-size(18); } }


.chef-member {
  position: relative;
  border-bottom: 1px solid $color_theme;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease;
  max-width: 265px;
  margin: 0 auto;

  .chef-thumb {
    position: relative;

    svg {
      position: absolute;
      left: 0;
      bottom: -47px;
      fill: $color_theme;
      transform: scale(0);
      transition: all 0.3s ease-in-out; } }


  .chef-details {
    position: absolute;
    left: 0;
    bottom: -100px;
    width: 100%;
    padding-top: 80px;
    padding-bottom: 20px;
    transition: all 0.7s ease-in-out;

    h3 {
      color: #FFF;
      margin-top: 0;
      @include font-size(24);
      font-weight: 500; }

    p {
      margin-bottom: 0;
      color: #FFF;
      @include font-size(16); } }


  &:hover {
    border-color: transparent;
    svg {
      transform: scale(1); }

    .chef-details {
      bottom: 0; } } }


/* Applo Chef Two */
.applo-chef-two {
  .chef-member {
    border-bottom: 0; } }
.chef-details {

  &.chef-two {
    padding: 20px 0;
    backface-visibility: hidden;
    bottom: 0;
    border-radius: 20px;
    transition: all 0.3s ease-in-out;
    height: 100px;

    .chef-thumb {
      img {
        width: 100%; } }

    .big-social-link {
      margin-top: 25px;
      transition: all 0.3s ease-in-out;

      li {
        height: 35px;
        width: 35px;
        border-radius: 5px;
        margin-right: 5px;
        border-color: #FFF;

        a {
          @include font-size(18);
          line-height: 36px; }

        &:hover {

          background: #FFF;
          a {
            color: #000; } } } }

    &:hover {
      height: 150px; } }

  &.color-1 {
    background: #c21fff; }

  &.color-2 {
    background: #00063e; }

  &.color-3 {
    background: #5fc23c; }

  &.color-4 {
    background: $color_theme; } }


