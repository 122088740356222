.footer_contact {
  padding: 150px 0;
  background-size: cover;
  background-position: center center;

  .footer_title {
    color: #111;
    @include font-size(36);
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 40px; } }

.info {
  margin-bottom: 40px;
  .info-icon {
    float: left;
    height: 60px;
    width: 60px;
    display: flex;
    border-radius: 50%;
    position: relative;

    i {
      display: flex;
      align-items: center;
      margin: 0 auto;
      color: #FFF;
      @include font-size(30); }

    &:before {
      position: absolute;
      content: '';
      border: 5px solid #FFF;
      left: -5px;
      right: -5px;
      top: -5px;
      bottom: -5px;
      border-radius: 50%;
      box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.2); }

    &.color-1 {
      background: #5fc23c; }

    &.color-2 {
      background: $color_theme; }

    &.color-3 {
      background: #00063e; } }


  .content {
    margin-left: 90px;
    h4 {
      @include font-size(16);
      text-transform: uppercase;
      color: #555555;
      font-weight: 600; }

    p {
      color: #333333;
      margin-bottom: 0; } } }


.google-map {
  overflow: hidden;

  .gmap3-area {
    height: 350px; } }

#form {
  input[type=text], textarea {
    width: 100%;
    border: none;
    margin-bottom: 20px;
    outline: none;
    padding: 10px;
    color: #111;
    @include font-size(16); }

  input[type=text] {
    &:nth-child(1) {
      border-bottom: 4px double $color_theme; }

    &:nth-child(2) {
      border-bottom: 4px double #c21fff; } }


  textarea {
    border-bottom: 4px double #111;
    height: 120px;
    margin-bottom: 40px; }

  .dt-btn {
    width: 100%;
    padding: 15px 0;
    border: none;
    background: #5fc23c;

    &:hover {
      background: #c21fff; } } }
