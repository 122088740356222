.applo-video {
  padding: 100px 0;
  text-align: center;

  .section-title {
    color: #FFF;
    margin-bottom: 20px;
    margin-top: 0; }

  p {
    color: #FFF;
    margin-bottom: 50px; } }


.plyr {
  input[type=range] {
    background: $color_theme;
    height: 8px; }

  .plyr__play-large {
    position: absolute;
    border: none;
    background-color: transparent;
    padding: 0;

    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: "\f04b";
      font: normal normal normal 50px/1 FontAwesome;
      height: 130px;
      width: 130px;
      border-radius: 50%;
      border: 4px solid $color_theme;
      line-height: 122px;
      color: $color_theme;
      padding-left: 12px; }


    svg {
      display: none; } } }

.plyr--video {
  .plyr__controls {
    background: transparent;
    padding: 15px 10px;
    width: 70%;
    margin: 0 auto 10px;
    border-radius: 3px;

    button {
      &:hover {
        background: $color_theme; } } } }

.plyr--video {
  .plyr__progress--buffer, .plyr__volume--display {
    background: #FFF;
    height: 2px; } }

.plyr__progress--played, .plyr__volume--display {
  height: 3px;
  color: $color_theme; }

/* Applo Video TWo */
.applo-video-two {
  padding: 100px 0;
  background-size: cover;
  background-attachment: fixed;

  overflow: hidden; }

.applo-video-two {
  text-align: center;

  .section-title {
    color: #FFF;
    text-transform: uppercase;
    font-size: 48px;
    font-weight: 300;
    margin-top: 0;

    span {
      color: #5fc23c; } }

  p {
    color: #FFF;
    font-size: 16px;
    margin-bottom: 130px; } }

#video-slider {
  position: relative;


  .slider-overlay {
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0,0,0, 0.5);
    height: 100%;
    width: 100%;

    .play {
      display: none;

      img {
        width: auto;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto; } } }

  .center {
    .slider-overlay {
      background: none;

      .play {
        display: block;
        cursor: pointer; } } } }

.lg-backdrop {
  background-color: rgba(0,0,0, 0.9); }


