#about-us {
  padding: 100px 0 0;
  position: relative;
  background-size: cover; }

.about-content {
  h2 {
    @include font-size(40);
    color: #00063e;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 50px;
    margin-top: 120px; }


  p {
    color: #00063e;
    margin-bottom: 40px; }

  .dt-btn {
    padding: 17px 60px;
    text-transform: uppercase; }


  .color-1 {
    background: #00063e;
    margin-right: 20px;
    &:hover {
      background: $color_theme; } }

  .color-2 {
    background: #c21fff;
    &:hover {
      background: $color_theme; } } }
