/*--------------------------------------------------------------
  ##  Loading
  --------------------------------------------------------------*/
@import "loading";

/*--------------------------------------------------------------
  ##  Header
  --------------------------------------------------------------*/
@import "header";

/*--------------------------------------------------------------
  ##  Banner
  --------------------------------------------------------------*/
@import "banner";

/*--------------------------------------------------------------
  ##  Feature
  --------------------------------------------------------------*/
@import "feature";

/*--------------------------------------------------------------
  ##  Screenshots
  --------------------------------------------------------------*/
@import "screenshots";

/*--------------------------------------------------------------
  ##  Food
  --------------------------------------------------------------*/
@import "food";

/*--------------------------------------------------------------
  ##  Applo Video
  --------------------------------------------------------------*/
@import "applo-video";

/*--------------------------------------------------------------
  ##  Pricing
  --------------------------------------------------------------*/
@import "pricing";

/*--------------------------------------------------------------
  ##  Get Applo
  --------------------------------------------------------------*/
@import "get-applo";

/*--------------------------------------------------------------
  ##  Applo Chef
  --------------------------------------------------------------*/
@import "applo-chef";

/*--------------------------------------------------------------
  ##  Testimonial
  --------------------------------------------------------------*/
@import "testimonial";

/*--------------------------------------------------------------
  ##  Applo Feature
  --------------------------------------------------------------*/
@import "applo-feature";

/*--------------------------------------------------------------
  ##  Newsletter
  --------------------------------------------------------------*/
@import "newsletter";

/*--------------------------------------------------------------
  ##  Footer Contact
  --------------------------------------------------------------*/
@import "footer_contact";

/*--------------------------------------------------------------
  ##  Footer
  --------------------------------------------------------------*/
@import "footer";

/*--------------------------------------------------------------
  ##  Blog-banner
  --------------------------------------------------------------*/
@import "blog-banner";

/*--------------------------------------------------------------
  ##  Blog-post
  --------------------------------------------------------------*/
@import "blog-post";

/*--------------------------------------------------------------
  ##  Blog Single Page
  --------------------------------------------------------------*/
@import "blog-single-page";


/*--------------------------------------------------------------
  ##  Woocommerce
  --------------------------------------------------------------*/
@import "woocommerce";

/*--------------------------------------------------------------
  ##  Woocommerce Single
  --------------------------------------------------------------*/
@import "woocommerce-single";

/*--------------------------------------------------------------
  ##  About
  --------------------------------------------------------------*/
@import "about";

/*--------------------------------------------------------------
  ## Responsive
  --------------------------------------------------------------*/
@import "responsive";
