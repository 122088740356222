#testimonial {
  padding: 150px 0;
  text-align: center;
  background-size: cover;


  .section-title {
    color: #FFF; }


  .caption {
    color: #FFF;
    @include font-size(18);
    margin-bottom: 100px; }

  .owl-theme {
    .owl-nav {
      display: none; } } }

.testimonial-item {
  text-align: center;
  cursor: pointer;

  .testi-tuumb {
    height: 120px;
    width: 120px;
    border-radius: 50%;
    background: $color_theme;
    padding: 5px;
    margin: 0 auto;
    margin-bottom: 30px;
    transition: all 0.3s ease-in-out;

    img {
      width: 100%;
      border-radius: 50%;
      border: 5px solid #FFF;
      transition: all 0.3s ease-in-out; } }

  .client-talk {
    padding: 30px 0;
    text-align: center;
    border: 2px solid $color_theme;
    border-radius: 30px;

    p {
      margin-bottom: 0;
      color: #FFF;
      font-size: 16px; } }

  h2 {
    @include font-size(25);
    font-weight: 500;
    color: $color_theme; }


  span {
    color: #FFF;
    display: block;
    margin-bottom: 30px; } }



.owl-theme {
  .owl-dots {
    .owl-dot {
      span {
        height: 20px;
        width: 20px;
        border: 4px solid $color_theme;
        color: #FFF; }


      &.active {
        span {
          background: #FFF;
          // border: 6px solid $color_theme
          height: 22px;
          width: 22px; } }

      &:hover {
        span {
          background: #FFF; } } } } }

/* Testimonial Two */
.testimonial-two {
  .testimonial-item {
    &.color-1 {
      cursor: pointer;
      &:hover {
        .testi-tuumb {
          background: #fff;
          img {
            border-color: $color_theme; } }

        .client-talk {
          border-color: #FFF; } } }

    &.color-2 {
      h2 {
        color: #c21fff; }

      .testi-tuumb {
        background: #c21fff; }

      .client-talk {
        border-color: #c21fff; }


      &:hover {
        .testi-tuumb {
          background: #FFF;
          img {
            border-color: #c21fff; } }

        .client-talk {
          border-color: #FFF; } } }

    &.color-3 {
      h2 {
        color: #5fc23c; }

      .testi-tuumb {
        background: #5fc23c; }

      .client-talk {
        border-color: #5fc23c; }

      &:hover {
        .testi-tuumb {
          background: #FFF;
          img {
            border-color: #5fc23c; } }

        .client-talk {
          border-color: #FFF; } } } }



  .owl-theme {
    .owl-dots {
      .owl-dot {
        span {
          height: 20px;
          width: 20px;
          border: 4px solid #c21fff;
          color: #5fc23c;
          background: #00063e;
          position: relative; }


        &.active {
          span {
            &:before {
              border: 4px solid #00ffff;
              position: absolute;
              left: -4px;
              right: -4px;
              top: -4px;
              bottom: -4px;
              content: '';
              background: #00063e;
              border-radius: 50%; } } }

        &:hover {
          span {
            background: #00063e; } } } } } }
