html {
  font-size: 14px; }

body {
  @include font-smoothing;
  color: $color_text;
  font-family: $font_primary;
  font-size: 14px;
  backface-visibility: hidden; }



.section-title {
  @include font-size(48);
  color: #000;
  font-weight: 300;
  margin-top: 30px;
  margin-bottom: 50px;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 20px;

  span {
    color: $color_theme; } }

.margin-none {
  margin: 0 !important; }

