#food {
  padding: 180px 0;
  background-size: cover;
  background-image: url(../../static/food/food_bg.jpg);

  .food-thumb {
    max-width: 550px;
    margin: 0 auto;
    img {
      width: 100%; } } }

.food {
  margin-top: 40px; }

.food-item {
  margin-bottom: 65px;
  .food-icon {
    float: left;
    background: #ffc269;
    height: 160px;
    width: 160px;
    border-radius: 50%;
    text-align: center;
    padding: 13px;
    transition: all 0.1s ease-in-out;

    .white-border {
      border: 3px solid #FFF;
      height: 135px;
      width: 135px;
      border-radius: 50%;
      transition: all 0.5s ease-in-out;

      i {
        background: $color_theme;
        height: 129px;
        width: 129px;
        display: block;
        border-radius: 50%;
        @include line-height(125);
        transition: all 0.1s ease-in-out; }

      [class^="flaticon-"] {
        &:before {
          @include font-size(70);
          color: #FFF;
          margin-left: 0;
          transition: all 0.1s ease-in-out; } } } }

  .food-content {
    margin-left: 200px;

    h3 {
      color: #2c2b2a;
      @include font-size(30);
      margin-bottom: 10px;
      font-weight: 500;
      transition: all 0.1s ease-in-out; }


    p {
      color: #676665;
      @include line-height(22);
      @include font-size(16); } }


  &:hover {
    cursor: pointer;

    .food-icon {
      box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.2);
      .white-border {
         box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3); } }


    .food-content {
      h3 {
        color: $color_theme; } } } }






