// Fonts
$font_primary: 'Fira Sans', sans-serif;


// Color
$color_theme: #ff9700;
$color_theme-2: #c21fff;
$color_theme-3: #ecedef;
$color_text: #6d6c6c;
$color_text-highlight: #111111;
