.blog-posts-archives {
  .widget {
    margin-left: 70px; } }

#blog-post {
  padding: 100px 0;

  .blog-nav-tabs {
    margin-bottom: 50px;
    padding: 0;
    list-style: none;
    border-bottom: 1px solid #ebebeb;

    li {
      display: inline-block;
      margin-right: 45px;
      position: relative;
      padding-bottom: 3px;

      &:after {
        content: '';
        position: absolute;
        background: $color_theme;
        width: 0;
        height: 3px;
        left: 0;
        right: 0;
        bottom: -2px;
        transition: all 0.3s ease-in-out; }

      a {
        color: #c3c3c3;
        text-decoration: none;
        text-transform: uppercase;
        transition: all 0.3s ease-in-out; }


      &:hover {
        &:after {
          width: 100%; }

        a {
          color: $color_theme; } }
      &:last-child {
        float: right;
        margin-right: 0;

        .search-input {
          border: none;
          outline: none; }

        .form-search-input {
          width: 0;
          transition: all 0.3s ease-in-out;
          border: none; }

        .search-input-open {
          width: 150px;
          outline: none; } } } }

  .post-item {
    margin-bottom: 30px;
    .post-thumbs {
      float: left; }


    .post-content {
      margin-left: 390px;

      .post-title {
        color: $color_theme;
        margin-top: 0;
        @include font-size(24); }

      p {
        color: #777777;
        @include font-size(16);
        margin-bottom: 30px; }

      .dt-btn {
        padding: 15px 28px;
        @include font-size(12);
        @include line-height(12);
        background: $color_theme;
        border: none;
        color: #FFF;

        &:hover {
          background: #000; } } } } }

.post-meta {
  margin-bottom: 20px;
  padding: 0;
  list-style: none;

  li {
    display: inline-block;
    padding-right: 10px;
    margin-left: 10px;


    &:first-child {
      padding-left: 0;
      position: relative;

      &:after {
        content: '';
        background: #000;
        height: 12px;
        width: 1px;
        right: 0;
        top: 3px;
        position: absolute; } }

    &:first-child {
      margin-left: 0; }


    a {
      color: #000;
      text-decoration: none;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: $color_theme; } } } }


.page-control-inner {
  text-align: center;
  margin-top: 45px;

  .page-control {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      display: inline-block;
      margin-right: 10px;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      border: 2px solid $color_theme;
      text-align: center;

      a {
        color: $color_theme;
        @include line-height(36);
        text-decoration: none;
        transition: all 0.3s ease-in-out;
        display: block;
        border-radius: 50%;

        &:hover {
          background: $color_theme;
          color: #FFF;

          text-decoration: none; } } } } }





