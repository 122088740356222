@media (max-width: 1440px) {
  /* Banner */
  .banner-container {
    .slider-content {
      .slider-title {
        span {
          left: 35%; } } } }


  .tab-wrapper {
    width: 1200px;
    .nav {
      li {
        width: 175px; } } }

  .tab-content {
    padding: 0 27px;

    img {
      width: 100%; } } }

@media (max-width: 1280px) {

  /* Banner */
  .dt-half-width {
    left: 5%; }

  .dt-half-width {
    &:last-child {
      right: 5%;
      height: 320px; } }


  .banner-container {
    .slider-content {
      .slider-title {
        h2 {
          @include font-size(38);

          &:after {
            right: -13px;
            height: 42px; } }

        span {
          @include font-size(22);
          left: 27%;
          top: 24px; } }

      p {
        @include font-size(16); }

      .dt-btn {
        padding: 14px 55px; } }

    .slider-images {
      width: 50%;
      img {
        width: 100%;
        height: auto; } } }



  /* Screenshot */
  .tab-wrapper {
    .nav {
      margin: 0 50px;
      li {
        width: 150px; } } }

  .tab-content {
    padding: 0 48px;

    img {
      width: 100%; } } }


@media (max-width: 1024px) {

  /* Header */

  #dt-header {
    top: 50px; }

  .menu-toggle {
    margin: 10px 0;
    padding: 0; }

  .navbar-header {
    .navbar-brand {
      width: 130px;
      img {
        width: 100%; } } }

  .section-title {
    @include font-size(36); }

  /* Banner */
  .dt-half-width {
    &:last-child {
      right: 5%;
      height: 250px; } }

  .banner-container {
    .slider-content {
      .slider-title {
        h2 {
          @include font-size(38);

          &:after {
            right: -13px;
            height: 42px; } }

        span {
          @include font-size(22);
          left: 34%;
          top: 24px; } }

      p {
        @include font-size(16);
        @include line-height(25);
        margin-bottom: 20px;

        br {
          display: none; } }


      .dt-btn {
        padding: 12px 45px; } } }


  /* Feature */

  #feature {
    background: none !important;

    .about {
      padding: 50px 0;
      p {
        @include font-size(16); } }

    .feature-thumb {
      padding: 20px; } }

  .feature-content {
    padding: 0 15px; }

  .feature {

    .feature-thumb {
      max-width: 350px !important;

      .feature-item {
        li {
          @include font-size(14); } } } }

  /* Feature Two */
  #feature-two {
    padding: 50px 0;
    .feature-thumb {
      left: -200px;
      top: -65px;
      width: 550px; }

    .feature_content {
      h2 {
        margin-top: 0; } } }

  .app-btn {
    padding: 10px 18px; }



  /* ScreenShot */
  .tab-wrapper {
    width: 100%;
    .nav {
      margin-left: 26px;
      margin-right: 0; } }

  .tab-content {
    padding: 0 26px; }

  /* Screenshot Two */
  .screenshot-two {
    padding: 100px 0 !important; }


  /* Applo Video Two */
  .applo-video-two {
    padding: 50px 0;
    .section-title {
      h2 {
        font-size: 36px; }
      p {
        margin-bottom: 50px;
        font-size: 14px; } } }

  #video-slider {
    .slider-overlay {
      .play {
        img {
          width: 60px; } } } }

  /* Applo Feature */
  .applo-thumb {
    margin-top: 80px;
    max-width: 350px;

    img {
      width: 100%; } }

  .feature-wrapper {
    background-image: none !important;

    .applo-thumb {
      margin-top: 20px;
      max-width: 350px;

      img {
        width: 100%; } } }

  .applo-feature-item {
    margin-bottom: 95px; }

  .feature-content {
    padding: 0; }

  .feature-left {
    .feature-content {
      margin-right: 95px;

      p {
        br {
          display: none; } } } }

  .position-one {
    margin-right: 0; }

  .position-right-one {
    margin-left: 0; }



  /* Get Applo */
  #get-applo {
    padding: 70px 0; }

  /* Chef Member */
  .chef-member {
    img {
      width: 100%; } }


  /* Food */
  #food {
    padding: 50px 0; }

  .food {
    margin-top: 115px; }

  .food-thumb {
    img {
      width: 100%; } }

  .food-item {
    margin-bottom: 20px;

    .food-icon {
      height: 100px;
      width: 100px;
      padding: 8px;

      .white-border {
        height: 84px;
        width: 84px;

        i {
          height: 77px;
          width: 77px;
          @include line-height(77); }

        [class^="flaticon-"] {
          &:before {
            @include font-size(45); } } } }

    .food-content {
      margin-left: 115px;

      h3 {
        @include font-size(22);
        margin-top: 0; } } }

  /* Testimonial  */
  #testimonial {
    padding: 100px 0; }

  .testimonial-item {
    .client-talk {
      p {
        br {
          display: none; } } } }



  /* Woocommeres */

  .woocommerce_items {
    .woocommerce {
      .content {
        .woo_title {
          @include font-size(16); }

        .buy {
          span {
            padding-right: 15px; } } } } }

  .applo-cart {
    .add-cart {
      .dt-btn {
        padding: 12px 24px; }

      #item_number {
        padding: 10px;
        width: 60px; } }

    .cart-thumb {
      float: none;
      margin-bottom: 20px;

      img {
        width: 100%; } }

    .cart-content {
      margin-left: 0; } }

  .woo_tabs {
    .woo_nav_tabs {
      top: 63%;
      left: 30%;
      bottom: auto; } } }


@media (max-width: 768px) {

  /* Header */

  .dt-slide-menu {
    width: 250px !important;
    right: -250px;
    padding: 30px 25px;
    overflow-y: scroll; }

  .cbp-spmenu-push-toleft {
    left: -250px; }

  #dt-header {
    top: 20px;
    .navbar-brand {
      float: left;
      width: 90px;
      img {
        width: 100%; } }

    .menu-toggle {
      float: right;
      margin: 4px 0; } }

  .feature-thumb {
    img {
      width: auto; } }

  .feature-content {
    padding: 0 20px; }


  /* Banner */
  #dt-banner {
    height: 400px; }

  .dt-half-width {
    left: 3%;
    width: 44%;
    &:last-child {
      right: 5%;
      height: 180px;

      .slider-images {
        .mobile-2 {
          margin-left: 40px; }

        .mobile-3 {
          margin-left: 80px; } } } }

  .banner-container {
    .slider-content {
      .slider-title {
        h2 {
          @include font-size(32);
          margin-bottom: 10px;

          &:after {
            right: -7px;
            height: 30px; } }

        span {
          @include font-size(14);
          left: 42%;
          top: 24px; } }

      p {
        @include font-size(14);
        @include line-height(22);
        margin-bottom: 15px; }

      .dt-btn {
        padding: 10px 24px;
        @include font-size(13);
        @include line-height(13);
        margin-right: 0px; } } }


  /* Aboute */
  #aboute {
    padding: 50px 0; }

  .about-content {
    h2 {
      margin: 20px 0;
      @include font-size(30); }

    p {
      margin-bottom: 15px; }

    .dt-btn {
      padding: 10px 20px;
      margin-top: 20px; } }




  /* Screenshot */
  #screenshot {
    .caption {
      margin-bottom: 50px; } }

  .tab-wrapper {
    .nav {
      max-width: 220px;
      margin: 0 auto;

      li {
        width: 100px; } } }

  .tab-content {
    max-width: 177px;
    margin: 0 auto;
    padding: 0; }


  /* ScreenShot two */

  .screenshot-two {
    padding: 50px 0 !important;
    &:before {
      display: none; }

    .section-title {
      margin-top: 0; }
    .screenshoot-container {
      .top-mobile {
        width: 150px;
        top: -36px; }

      .testi-tuumb {
        width: 131px;
        height: 300px; } } }

  /* Feature */
  #feature {
    .feature {
      .feature-item {
        li {
          font-size: 15px; } } } }
  .feature-wrapper {
    .applo-thumb {
      margin: 0 auto; } }
  /* Feature Two */
  #feature-two {
    padding: 50px 0;
    .feature-thumb {
      display: none; }
    .feature_content {
      h2 {
        @include font-size(30); } } }



  /* Food */
  .food {
    margin-top: 40px; }


  /* Applo Video */
  .plyr {
    .plyr__play-large {
      &:before {
        font: normal normal normal 40px/1 FontAwesome;
        height: 80px;
        width: 80px;
        line-height: 75px; } } }


  /* Pricing */
  #pricing {
    padding: 100px 0; }

  .price-table {
    margin-bottom: 20px; }

  /* Get Applo */
  #get-applo {
    padding: 50px 0;

    .section-title {
      margin-top: 40px; }

    .applo-caption {
      margin-bottom: 30px; } }

  .app-btn {
    padding: 10px 20px; }

  .countup {
    margin-top: 50px; }

  /* Applo Feature */
  #applo-feature {
    padding: 50px 0;
    background-image: none !important;

    .caption {
      margin-bottom: 50px; } }

  .applo-feature {
    margin-top: 20px; }

  .applo-thumb {
    img {
      margin-top: 60px; } }



  /* Footer */
  .search {
    .input-group {
      margin: 0 auto;
      .form-control {
        width: 300px;
        height: 50px;
        padding-right: 50px; } }


    .dt-btn {
      height: 50px;
      @include font-size(14);
      padding: 15px 50px;
      margin-left: -50px; } }

  .big-social-link {
    li {
      height: 60px;
      width: 60px;
      border-radius: 15px;

      a {
        @include font-size(36);
        line-height: 62px; } } }

  /* Blog */

  #blog-post {
    .post-item {
      .post-thumbs {
        float: none;
        margin-bottom: 20px;

        img {
          width: 100%; } }

      .post-content {
        margin-left: 0; } } }

  .page-control-inner {
    .page-control {
      margin: 30px 0; } }

  .blog-post {
    .widget {
      margin-left: 0; } }

  #blog_single {
    .widget {
      margin-left: 0; } } }

@media (max-width: 600px) {
  /* Screenshot */
  .tab-wrapper {
    .nav {
      li {
        width: 80px; } } }


  .tab-content {
    padding: 0 18px; } }


@media (max-width: 500px) {

  .section-title {
    @include font-size(24); }

  .caption {
    @include font-size(14); }

  /* Slide Menu */
  .dt-slide-menu {
    width: 250px !important;
    right: -250px;
    padding: 30px 25px;
    overflow-y: scroll; }

  .cbp-spmenu-push-toleft {
    left: -250px; }

  /* Banner */
  .parallax-bg {
    display: none; }


  .dt-half-width {
    width: 100%;
    left: 0; }

  .dt-half-width-img {
    display: none; }

  .banner-container {
    .slider-content {
      padding: 0 20px;
      .slider-title {
        span {
          left: 36%; } } } }


  /* Feature */
  #feature {
    .feature {
      .feature-item {
        li {
          @include font-size(14);

          img {
            margin-right: 10px; } } } }

    .feature-thumb {
      img {
        width: 100%; } } }

  /* About */
  .about-thumb {
    width: 90%;
    img {
      width: 100%; } }

  .about-content {
    h2 {
      @include font-size(20); } }


  /* Screenshot */
  .tab-inner {
    width: 100%; }

  .tab-body {
    padding: 20px 50px; }

  .tab-wrapper {
    .nav {
      margin-left: 35px;
      max-width: 100%;
      li {
        width: 70px; } } }


  /* ScreenShoot Two */
  .screenshot-two {
    .screenshoot-container {
      .top-mobile {
        width: 230px; }


      .testi-tuumb {
        width: 215px;
        height: 450px; } } }


  /* get Applo */
  .applo {
    .applo-thumb {
      img {
        width: 100%; } } }

  .app-btn {
    margin-right: 0;
    margin-bottom: 20px; }


  .plyr .plyr__play-large {
    &:before {
      font: normal normal normal 25px/1 FontAwesome;
      height: 60px;
      width: 60px;
      border: 2px solid #ff9700;
      line-height: 60px;
      padding-left: 6px; } }

  /* Testimonial */
  #testimonial {
    padding: 50px 0;

    .caption {
      margin-bottom: 30px;
      @include font-size(14); } }

  /* Feature */
  .applo-feature-item {
    margin-bottom: 35px; }

  .feature-content {}





  /* Newsletter */
  #newsletter {
    padding: 50px 0; }

  .newsletter-two {
    .social-wrapper {
      width: auto;
      h3 {
        float: none;
        margin-bottom: 20px; } } }

  /* Footer */
  .footer_contact {
    padding: 50px 0;

    .footer_title {
      @include font-size(28); } }

  .gmap3-area {
    margin-bottom: 20px; }

  /* Food */
  #food {
    .food-thumb {
      img {
        width: 100%; } } }


  /* Footer */

  .big-social-link {
    li {
      height: 50px;
      width: 50px;
      border-radius: 15px;

      a {
        @include font-size(26);
        line-height: 52px; } } }

  /* Blog */

  #blog-post {
    .blog-nav-tabs {
      li {
        margin-right: 10px; } } }

  #commentform {
    input[type="text"] {
      width: 100%; } }

  .comments-area {
    .comment-list {
      li {
        .comment-body {
          .comment-meta {
            right: 0;
            left: auto; } } } } }

  #blog_banner {
    height: 300px; }

  .page_caption {
    .page-title {
      font-size: 30px; } }

  /* Search */

  .search {
    .input-group {
      .form-control {
        width: 230px;
        height: 40px;
        @include font-size(14); } }

    .dt-btn {
      height: 40px;
      @include font-size(14);
      padding: 12px 20px;
      margin-left: -40px; } } }

