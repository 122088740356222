/* Instagram Gallary */

.instagram_gallary {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;
      margin-right: 2px;
      margin-bottom: 5px;
      border-radius: 5px;
      padding: 0;

      a {
        img {
          border-radius: 5px;
          transition: all 0.3s ease-in-out; }

        &:hover {

          img {
            opacity: 0.5; } } } } } }
