#screenshot {
  padding: 100px 0;
  text-align: center;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;


  &.screenshot-two {
    background-attachment: inherit; }



  .section-title {
    color: #FFF; }

  .caption {
    @include font-size(16);
    color: #FFF;
    margin-bottom: 150px; } }

.dt-tabs {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    display: inline;
    margin-right: 10px;
    margin-bottom: 20px; } }

.tab-wrapper {
  width: 1420px;
  margin: 0 auto; }


.tab-wrapper {
  .nav {
    border-bottom: none;
    margin-left: 25px;

    li {
      margin: 5px;
      a {
        border: none;
        padding: 0px;
        background: transparent;
        display: block;

        img {
          width: 100%; }

        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          background-color: rgba(254,159,21, 0.8);
          height: 100%;
          width: 100%;
          padding: 10px;
          opacity: 0;
          transition: all 0.3s ease-in-out;

          i {
            @include font-size(40);
            color: #FFF;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%); } }


        &:focus, &:hover {
          background: transparent;
          border: none;

          .overlay {
            opacity: 1; } } } } } }


.tab-inner {
  float: left;
  width: 35%; }

.tab-body {
  width: 30%;
  float: left; }

.fade {
  transition: opacity .5s linear; }

/* ScreenShot */
.screenshot-two {
  position: relative;
  padding: 200px 0 300px!important;
  position: relative;


  .section-title {
    margin-top: 50px; }

  &:before {
    position: absolute;
    background: #FFF;
    height: 200%;
    width: 200%;
    top: -186%;
    left: -50%;
    content: '';
    z-index: 50;
    border-bottom-left-radius: 170%;
    border-bottom-right-radius: 170%; }

  .screenshoot-container {
    width: 90%;
    margin: 0 auto;
    position: relative;

    .testi-tuumb {
      width: 265px;
      height: 475px;
      margin: 0 auto;

      img {
        width: 100%; } }

    .top-mobile {
      position: absolute;
      top: -71px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1000;
      width: 300px;

      img {
        width: 100%;
        height: auto; } } } }

.overlay-image[data-bg-image] {
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; }

// .sape
//   position: absolute
//   bottom: -200px
//   width: 100%
//   left: 0
//   height: 300px

//   &:before, &:after
//     width: 100%
//     height: 300px
//     left: 0
//     content: ''

//     position: absolute

//   &:before
//     background: #c21fff
//     bottom: 35px
//     border-top-right-radius: 45%

//   &:after
//     background: #FFF
//     bottom: 0
