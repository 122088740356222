/*!
  Theme Name: Applo
  Theme URI: http://www.decentthemes.com/products/applo-html/
  Author: Decent Themes
  Author URI: http://www.decentthemes.com/
  Description: Food & Recepe Application Landing Page
  Version: 1.0.0
  License: GNU General Public License v2 or later
  License URI: http://www.gnu.org/licenses/gpl-2.0.html
  Text Domain: applo
  Tags:
  */
/*--------------------------------------------------------------
  >>> TABLE OF CONTENTS:
  ----------------------------------------------------------------
  # Base rules
  # Library
    ## Example
  # Elements
    ## Accessibility
    ## Buttons
  # Navigation Menu
    # Primary Menu
  # Sections
    ## Loading
    ## Header
    ## Banner
    ## Feature
    ## Screenshots
    ## Screenshot
    ## Food
    ## Applo Video
    ## Procing
    ## Get Applo
    ## Applo Chef
    ## Testimonial
    ## Applo Feature
    ## Newsletter

  # Loader

  # Widgets
    ## Search
    ## Dt Custom Menu
    ## Dt Download Menu
    ## Dt Tags
    ## Dt Resent Post
    ## Dt Archive
  --------------------------------------------------------------*/

// Variables and Mixins
@import "base/variables";
@import "base/mixins";

/*--------------------------------------------------------------
  # Base rules
  --------------------------------------------------------------*/
@import "base/bootstrap";

/*--------------------------------------------------------------
  # Elements
  --------------------------------------------------------------*/
@import "elements/elements";

/*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
@import "navigation-menu/navigation";

/*--------------------------------------------------------------
  # Sections
  --------------------------------------------------------------*/
@import "sections/sections";

/*--------------------------------------------------------------
  # Loader
  --------------------------------------------------------------*/
@import "lib/loader";

/*--------------------------------------------------------------
  # Widgets
  --------------------------------------------------------------*/
@import "widgets/widgets";
