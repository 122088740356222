#woocommerce {
  padding: 100px 0; }

.woocommerce_items {
  .woocommerce {
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 20px;

    .thumbs {
      img {
        width: 100%; } }

    .content {
      background: #f7f7f7;
      padding: 20px;

      .woo_title {
        @include font-size(18);
        margin-top: 0;
        a {
          text-decoration: none;
          color: #313436;
          font-weight: 500;
          transition: all 0.3s ease-in-out;

          &:hover {
            color: $color_theme; } } }




      .buy {
        padding-top: 15px;
        border-top: 1px solid #dde7f0;

        span {
          @include font-size(16);
          font-weight: 500;
          color: $color_theme;
          display: inline-block;
          padding-right: 50px; }


        .dt-btn {
          padding: 13px 28px;
          @include font-size(12);
          @include line-height(12); } } } } }

.rateing {
  margin-bottom: 15px;
  padding: 0;
  list-style: none;

  li {
    display: inline-block;
    padding-right: 5px;
    color: red;

    i {
      color: $color_theme; } } }
