#woocommerce-signle {
  padding: 100px 0; }

.woo_tabs {
  position: relative;
  margin-bottom: 50px;
  .woo_nav_tabs {
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    bottom: -15px;
    left: 8%;


    li {
      display: inline-block;
      margin-right: 10px;
      height: 50px;
      width: 50px;
      padding: 5px;

      a {
        padding: 3px;
        border-radius: 4px;
        background: #FFF;
        display: block;
        transition: all 0.3s ease-in-out;

        img {
          width: 100%;
          border-radius: 4px; }

        &:hover {
          box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.4); } }

      &.active {
        a {
          box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.4); } } } } }



.applo-cart {
  .cart-thumb {
    float: left;
    border-radius: 10px;
    overflow: hidden; }

  .cart-content {
    margin-left: 430px; }

  .price {
    color: $color_theme;
    font-weight: 500;
    @include font-size(18);
    display: inline-block; }


  h3 {
    margin-top: 0;
    @include font-size(20);
    color: #282828;
    font-weight: 500;
    letter-spacing: 0.5px; }

  .rateing {
    margin-bottom: 25px;
    li {
      color: #adadad;
      @include font-size(10);
      position: relative;

      i {
        font-size: 14px; }

      &:nth-child(7) {
        padding-right: 12px;

        &:after {
          position: absolute;
          height: 8px;
          width: 1px;
          content: '';
          background: #adadad;
          right: 0;
          top: 2px; } }

      &:last-child {
        margin-left: 5px; } } }


  p {
    color: #282828;
    @include line-height(24);
    margin-bottom: 30px; }

  .online {
    margin-bottom: 25px;
    h5 {
      color: #4a4c4f;
      @include font-size(12);
      font-weight: 500;
      letter-spacing: 0.6px;
      margin-top: 0;
      text-transform: uppercase; }


    span {
      height: 15px;
      width: 15px;
      background: #d9d9d9;
      display: inline-block;
      border-radius: 50%;
      margin-right: 5px;

      &.staus {
        border: 4px solid $color_theme;
        background: #FFF; } } }

  .add-cart {
    h5 {
      color: #4a4c4f;
      @include font-size(12);
      font-weight: 500;
      letter-spacing: 0.6px;
      margin-top: 0;
      text-transform: uppercase; }

    .item_number {
      padding: 16px;
      width: 70px;
      @include font-size(12);
      border: 1px solid $color_theme;
      border-radius: 5px;
      outline: none;
      margin-right: 30px; }

    .dt-btn {
      @include font-size(12);
      padding: 16px 56px;
      text-decoration: none;
      text-transform: uppercase; } } }

.review-tab {
  .review-nav-tabs {
    margin-bottom: 25px;
    padding: 0;
    list-style: none;
    background: #f1f1f1;
    border: 1px solid #e9e9e9;
    border-bottom: 2px solid $color_theme;

    li {
      display: inline-block;
      border-right: 1px solid #e9e9e9;
      margin-left: -4px;

      &:first-child {
        margin-left: 0; }


      a {
        display: block;
        padding: 10px 20px;
        text-decoration: none;
        transition: all 0.3s ease-in-out;
        color: #111;

        &:hover {
          background: $color_theme;
          color: #FFF; } }

      &.active {
        background: $color_theme;
        color: #FFF; } } }

  .content {
    color: #424242;
    @include font-size(16);
    @include line-height(24); } }

