#blog_banner {
  height: 500px;
  background-size: cover;
  position: relative;
  background-attachment: fixed; }


.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.6); }

.page_caption {
  position: absolute;
  bottom: 50px;

  .page-title {
    color: #FFF;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 300;
    @include font-size(48); }

  .page-slug {
    color: #FFF;
    text-transform: uppercase;

    span {
      color: $color_theme; } } }
